import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  InputAdornment,
  TextField,
  Avatar,
  Typography,
  Pagination,
  Skeleton,
  Stack,
  Tooltip,
  Button,
  Autocomplete,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  Chip,
  CircularProgress,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import { TableColumn, TableData } from "./type";
import { ListTableWrapper, AntSwitch } from "./ListTable.styled";
import SearchIcon from "@mui/icons-material/Search";
import { truncateText } from "../../common/helper";
import { CheckBox } from "@mui/icons-material";
// import { useNavigate } from "react-router-dom";
import { debounce, findIndex } from "lodash";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { checkPermissions } from "../../utils/roles/permissionUtils";

interface ListTableProps {
  tabValue: string;
  columns: TableColumn[];
  data: TableData[];
  tableName?: string;
  isSubmitClicked?: boolean;
  downloadCsv?: any;
  downloadCsvReport?: any;
  handleEdit?: () => void;
  actions?: {
    icon?: React.ReactNode;
    label: string;
    color: string;
    onClick: (item: TableData) => void;
    disabled?: boolean;
  }[];
  itemsPerPage?: number;
  onActionClick?: (action: any, item: TableData) => void;
  isStatus?: boolean;
  timeout: number;
  renderRow?: (
    item: TableData,
    index: number,
    searchQuery: any
  ) => React.ReactNode;
  heading?: string;
  buttonText?: string;
  selectedYear?: string;
  currentFinancialYear?: string;
  tableData?: TableData;
  isDraft?: boolean;
  handleEditButton?: (item: TableData) => void;
  isEditMode?: boolean;
  onChange?: any;
  buOptions?: string[];
  handleAddNew?: any;
  isCancelClicked?: boolean;
  designationValue?: any;
  designationOnChange?: any;
  designationOptions?: any;
  setLoadingState?: (loading: boolean) => void; // Define the prop type
  isEditDisabled?: boolean;
}

function ListTable({
  tabValue,
  columns,
  data,
  tableName,
  isSubmitClicked,
  downloadCsv,
  downloadCsvReport,
  isCancelClicked,
  handleEdit,
  handleEditButton,
  isEditMode,
  actions,
  itemsPerPage = 10,
  onActionClick,
  isStatus = true,
  timeout,
  renderRow,
  heading,
  buttonText,
  selectedYear,
  currentFinancialYear,
  tableData,
  isDraft,
  onChange,
  buOptions = [],
  designationValue = [],
  designationOnChange = [],
  designationOptions = [],
  handleAddNew,
  setLoadingState,
  isEditDisabled
}: ListTableProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchPage, setSearchPage] = useState(1);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [filterDepartment, setFilterDepartment] = useState<string[]>([]);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const hasEEPermission = checkPermissions("ADELG", ["Edit"]);
  const hasAAPermission = checkPermissions("ADAPPRAISAL", ["Edit"]);
  const hasDSGNPermission = checkPermissions("ADDSGN", ["Edit"]);
  const hasSlabPermission = checkPermissions("ADSLM", ["Edit"]);

  const debouncedSearch = debounce((searchQuery) => {
    setDebouncedSearchQuery(searchQuery);
  }, 500);
  useEffect(() => {
    setLoadingState?.(isLoading);
  }, [isLoading]);

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery, tableName]);

  useEffect(() => {
    setSelectedDepartment("");
  }, [tableName]);

  useEffect(() => {
    if (tableName === "Career-level Appraisal") {
      setSearchQuery("");
      setDebouncedSearchQuery("");
    } else if (tableName === "Budget Details") {
      if (searchQuery !== "" || debouncedSearchQuery !== "") {
        setSearchQuery("");
        setDebouncedSearchQuery("");
      }
    }
  }, [tableName]);

  const handleTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    elementId: number,
    columnAccessor: string
  ) => {
    const newValue = e.target.value;
    const maxValue = tableName === "Slab Mapping" ? 150 : 100;

    const actualIndex = findIndex((tableData as TableData[]), (item) => (item.id === elementId))
    if (actualIndex >= 0 && actualIndex < (tableData as TableData[]).length) {
      const newTableData = [...(tableData as TableData[])];
      const accessorParts = columnAccessor.split('.');
      let targetObject = newTableData[actualIndex];
      for (let i = 0; i < accessorParts.length - 1; i++) {
        const part = accessorParts[i];
        if (!targetObject[part]) {
          targetObject[part] = {};
        }
        targetObject = targetObject[part];
      }
      const parsedValue = parseFloat(newValue);
      if (isNaN(parsedValue) || parsedValue < 0 || parsedValue > maxValue) {
        if (parsedValue > maxValue) {
          alert(`You cannot add more than ${maxValue}%`);
          return
        }
      }
      targetObject[accessorParts[accessorParts.length - 1]] = newValue === "" ? "" : parsedValue;
      onChange(newTableData);
    } else {
      console.error("Index out of bounds:", actualIndex);
    }
    return;
  };

  useEffect(() => {
    if (
      heading === "Eligibility" &&
      searchQuery === "" &&
      debouncedSearchQuery !== "" &&
      filterDepartment.length === 0
    ) {
      setDebouncedSearchQuery("");
      setCurrentPage(1);
      setSelectedDepartment("");
    } else if (
      heading !== "Eligibility" &&
      searchQuery === "" &&
      debouncedSearchQuery !== "" &&
      filterDepartment.length === 0
    ) {
      setDebouncedSearchQuery("");
      setCurrentPage(1);
    }
  }, [searchQuery]);

  const getValue = (item: any, column: any) => {
    if (column.accessor.includes(".")) {
      const accessorParts = column.accessor.split(".");
      if (accessorParts[0] === "budget_details") {
        return item.budget_details?.[accessorParts[1]] ?? 0;
      }
      return item[accessorParts[0]]?.[accessorParts[1]] ?? 0;
    }
    return item[column.accessor] ?? 0;
  };
  const filteredData = isLoading
    ? new Array(itemsPerPage).fill(null)
    : data
        ?.filter((item) => {
          if (
            selectedDepartment &&
            (item.buHead || item.department) !== selectedDepartment
          ) {
            return false;
          }
          if (heading === "Eligibility") {
            const lowerCaseSearchQuery = debouncedSearchQuery.toLowerCase();
            const hasMatchingEmployee = item.employees.some((employee: any) => {
              const employeeName =
                employee?.fullName?.toString().toLowerCase() || "";
              return employeeName.includes(lowerCaseSearchQuery);
            });
            return hasMatchingEmployee;
          } else {
            return columns.some((column) => {
              const columnAccessor = column?.accessor;
              const columnValue =
                item && columnAccessor ? item[columnAccessor] : undefined;
              if (columnValue !== undefined && columnValue !== null) {
                const lowerCaseColumnValue = columnValue
                  .toString()
                  .toLowerCase();
                const lowerCaseSearchQuery = debouncedSearchQuery.toLowerCase();
                return lowerCaseColumnValue.includes(lowerCaseSearchQuery);
              }
              return false;
            });
          }
        })
        .filter((item) => {
          if (filterDepartment.length === 0) {
            return true;
          }
          return filterDepartment.includes(item.department || item.buHead);
        });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleData = filteredData.slice(startIndex, endIndex);
  const totalRecords = filteredData?.length;
  useEffect(() => {
    if (currentPage > totalPages && totalPages !== 0) {
      setCurrentPage(totalPages);
    }

    if (visibleData.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [filteredData, currentPage, totalPages, visibleData]);

  useEffect(() => {
    setCurrentPage(searchPage);
  }, [searchPage]);

  useEffect(() => {
    setLoadingState?.(true);

    const timer = setTimeout(() => {
      setIsLoading(false);
      setLoadingState?.(false);

    }, timeout);

    return () => clearTimeout(timer);
  }, [timeout, currentPage]);

  const departmentOptions = data
    ?.map((department) => ({
      value: department.department,
      label: department?.department,
    }))
    .filter((option) => option.value !== undefined);

  const handleDepartmentChange = (event: any) => {
    setFilterDepartment(event.target.value as string[]);
  };

  return (
    <ListTableWrapper>
      <div className="list_table-main-table">
        <div
          className="table-row-item srch_position"
          style={{
            border: 0,
            marginBottom: heading === "Eligibility" ? "15px" : "-15px",
          }}
        >
          {tableName === "Budget Details" && (
            <Typography
              variant="h6"
              className="employee-details"
              component="h6"
              fontWeight="bold"
              style={{ flexGrow: 1 }}
            >
              Budget Details
            </Typography>
          )}
          {tableName === "Career-level Appraisal" && (
            <Typography
              variant="h6"
              className="employee-details"
              component="h6"
              fontWeight="bold"
              style={{ flexGrow: 1 }}
            >
              Career-level Appraisal
            </Typography>
          )}
          <Stack
            className="rbac_content_header"
            direction="row"
            justifyContent="space-between"
            flexGrow={1}
            alignItems="center"
            mb={2}
          >
            <Typography variant="h5">{heading}</Typography>
          </Stack>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: "16px", gap: "16px" }}
          >
            <Stack>
              <TextField
                label={heading === "Eligibility" ? "Search Employee" : "Search"}
                sx={{ marginBottom: "15px" }}
                variant="outlined"
                size="small"
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                style={{
                  width: "100%",
                  marginTop: heading === "Eligibility" ? "15px" : undefined,
                }}
              />
            </Stack>
            {heading === "Designations" && (
              <Autocomplete
                style={{ width: "150px" }}
                value={designationValue}
                onChange={designationOnChange}
                options={designationOptions}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) =>
                  option.value === value?.value
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ bottom: "8px" }}
                    label="Status"
                    variant="outlined"
                      size="small"
                      fullWidth
                    inputProps={{
                      ...params.inputProps,
                      "aria-label": "Status",
                    }}
                  />
                )}
              />
            )}
            {heading === "Budget Report" && (
              <>
                <FormControl variant="outlined" style={{ width: "210px" }}>
                  <InputLabel id="multi-select-label" sx={{ marginTop: "-16px"}}>Filter</InputLabel>
                  <Select
                    id="filter-select"
                    multiple
                    style={{ marginTop: "-11px", height: "40px" }}
                    value={filterDepartment}
                    onChange={handleDepartmentChange}
                    input={<OutlinedInput label="filter" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", gap: 0.3 }}>
                        {selected.length > 1 ? (
                          <Fragment>
                            {selected.slice(0, 1).map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                            <Chip label={`+${selected.length - 1}`} />
                          </Fragment>
                        ) : (
                          selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))
                        )}
                      </Box>
                    )}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 200,
                        },
                      },
                    }}
                  >
                    {buOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        <Checkbox
                          checked={filterDepartment.indexOf(option) > -1}
                        />
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  style={{ bottom: "8px" }}
                  startIcon={<DownloadIcon />}
                  onClick={downloadCsvReport}
                >
                  Download
                </Button>
              </>
            )}
            <Stack
              className="rbac_content_header"
              direction="row"
              justifyContent="space-between"
              flexGrow={1}
              alignItems="center"
              mb={2}
            >
              {buttonText && (
                <div
                  style={{
                    display: "flex",
                    paddingRight: "1rem",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "max-Content",
                    }}
                    variant="contained"
                    // className='cs_btn_type'
                    size="large"
                    onClick={handleAddNew}
                  >
                    {buttonText}
                  </Button>
                </div>
              )}
            </Stack>
            {heading === "Eligibility" && (
              <>
                <FormControl sx={{ width: "210px" }}>
                  <InputLabel
                    id="demo-multiple-checkbox-label"
                  >
                    Filter
                  </InputLabel>
                  <Select
                    id="filter-select"
                    multiple
                    style={{ marginTop: "3px", height: "38px" }}
                    value={filterDepartment}
                    onChange={handleDepartmentChange}
                    input={<OutlinedInput label="filter" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", gap: 0.3 }}>
                        {selected.length > 1 ? (
                          <Fragment>
                            {selected.slice(0, 1).map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                            <Chip label={`+${selected.length - 1}`} />
                          </Fragment>
                        ) : (
                          selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))
                        )}
                      </Box>
                    )}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 200,
                        },
                      },
                    }}
                  >
                    {departmentOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <Checkbox
                          checked={filterDepartment.includes(option.value)}
                        />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {hasEEPermission && (
                <Button
                  variant="contained"
                  disabled={!isSubmitClicked && !isCancelClicked}
                  onClick={handleEdit}
                >
                  Edit
                </Button>
                )}
                <Button
                  variant="contained"
                  startIcon={<DownloadIcon />}
                  disabled={!isSubmitClicked && !isCancelClicked}
                  onClick={downloadCsv}
                >
                  Download
                </Button>
              </>
            )}
            {tableName === "Budget Details" && (
              <>
                <Autocomplete
                  id="filter-select"
                  style={{ width: "195px" }}
                  options={data.map((item) => item.department)}
                  getOptionLabel={(option) => option}
                  // style={{ marginTop: "-15px", width: "100px" }}
                  onChange={(_event, newValue) =>
                    setSelectedDepartment(newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                    style={{ bottom: "8px" }}
                      {...params}
                      label="Filter"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                />
                {hasAAPermission && (
                <Button
                  variant="contained"
                  disabled={isEditMode || selectedYear !== currentFinancialYear}
                  onClick={handleEditButton}
                  style={{ marginTop: "-15px" }}
                >
                  Edit
                </Button>
                )}
              </>
            )}
            {tableName === "Career-level Appraisal" && (
              <>
              {hasAAPermission && (
                <Button
                  variant="contained"
                  disabled={!isDraft || selectedYear !== currentFinancialYear || isEditDisabled}
                  onClick={handleEditButton}
                  style={{ marginTop: "-15px" }}
                >
                  Edit
                </Button>
              )}
                {/* <Autocomplete
                  id="filter-select"
                  options={data?.map((item) => item?.name)}
                  getOptionLabel={(option) => option}
                  style={{ marginTop: "-15px", width: "100px" }}
                  onChange={(_event, newValue) =>
                    setSelectedDepartment(newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filter"
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                /> */}
              </>
            )}
            {tableName === "Slab Mapping" && hasSlabPermission && (
              <>
                <Button
                  variant="contained"
                  onClick={handleEditButton}
                  style={{ marginTop: "-15px" }}
                >
                  Edit
                </Button>
              </>
            )}
          </Box>
        </div>
        <TabContext value={tabValue}>
          <TabPanel value={tabValue} className="override-tabpanel">
            <div className="table-row-item-cell table-header-modify">
              {columns.map((column) => (
                <Box
                  key={column?.accessor}
                  className="list_table-table-head"
                  style={{
                    textAlign:
                      column.accessor === "budget_details.percentage"
                        ? "center"
                        : "left",
                  }}
                >
                  {column.header}
                </Box>
              ))}
              {isStatus && hasDSGNPermission && (
                <Box className="list_table-table-head list-table-status-head">
                  Status
                </Box>
              )}
              {Array.isArray(actions) && actions.length !== 0 && (
                <Box className="list_table-table-head list-table-action-head">
                  Actions
                </Box>
              )}

            </div>
            {isLoading ? (
              Array.from({ length: itemsPerPage / 2 }).map((_, index) => (
                <div className="table-row-item" key={index}>
                  {columns.map((column) => (
                    <Box
                      className="list-list_table-box-wrap"
                      key={column?.accessor}
                    >
                      <List>
                        <ListItem className="list-list_table">
                          <Skeleton
                            width={300}
                            animation="wave"
                            variant="text"
                          />
                        </ListItem>
                      </List>
                    </Box>
                  ))}
                  {isStatus && hasDSGNPermission && (
                    <Box className="list-list_table-box-wrap" key={"status"}>
                      <List>
                        <ListItem className="list-list_table">
                          <Skeleton
                            width={150}
                            animation="wave"
                            variant="text"
                          />
                        </ListItem>
                      </List>
                    </Box>
                  )}
                  {Array.isArray(actions) && actions.length != 0 && (
                    <Box className="list-list_table-box-wrap" key={"actions"}>
                      <List>
                        <ListItem className="list-list_table">
                          <Skeleton
                            width={150}
                            animation="wave"
                            variant="text"
                          />
                        </ListItem>
                      </List>
                    </Box>
                  )}
                </div>
              ))
            ) : visibleData && visibleData?.length === 0 ? (
              <p style={{ textAlign: "center", fontWeight: 400 }}>
                No data to display
              </p>
            ) : (
              visibleData?.map((item, index) => {
                return renderRow ? (
                  renderRow(item, index, searchQuery)
                ) : (
                  <div className="table-row-item" key={index}>
                    {columns?.map((column) => {
                      const value = getValue(item, column);
                      const elem =
                        item?.[column?.accessor] !== undefined
                          ? truncateText(item?.[column?.accessor], 30)
                          : "";
                      const desgArray = item["designation"];
                      const data = desgArray
                        ?.map((desg: { name: any }) => desg?.name)
                        .join(", ");

                      return (
                        <Box
                          className="list-list_table-box-wrap"
                          key={column?.accessor}
                          style={{
                            textAlign:
                              column.accessor === "percentage"
                                ? "right"
                                : "left",
                            marginLeft:
                              column.accessor === "percentage"
                                ? "auto"
                                : undefined,
                          }}
                        >
                          <List>
                            <ListItem
                              className={`list-list_table ${
                                tableName === "Budget Details"
                                  ? "percentage-wrap"
                                  : ""
                              }`}
                              sx={{
                                display:
                                  tableName === "Budget Details" ? "flex" : "",
                                justifyContent:
                                  tableName === "Budget Details"
                                    ? "center"
                                    : "block",
                              }}
                            >
                              {column.type === "image" ||
                                column.type === "Image" ? (
                                <Avatar
                                  alt="Image"
                                  src={decodeURIComponent(
                                    item?.[column?.accessor]
                                  )}
                                />
                              ) : column?.type === "html" ||
                                column?.type === "HTML" ? (
                                // Render HTML content
                                <div>
                                  {Array.isArray(elem) ? (
                                    elem.map((item, index) => (
                                      <div key={index}>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item,
                                          }}
                                        />
                                      </div>
                                    ))
                                  ) : (
                                    <div
                                      dangerouslySetInnerHTML={{ __html: elem }}
                                    />
                                  )}
                                </div>
                              ) : column?.accessor === "designation" ? (
                                <Tooltip title={data} arrow>
                                  <ListItemText
                                    className="listIem-text"
                                    primary={truncateText(data, 20)}
                                  />
                                </Tooltip>
                              ) : column?.accessor ===
                                "budget_details.percentage" ? (
                                selectedYear === currentFinancialYear ? (
                                  isDraft ? (
                                    <TextField
                                      value={value}
                                      disabled
                                      inputProps={{
                                        style: { textAlign: "center" },
                                      }}
                                    />
                                  ) : isEditMode ? (
                                    <TextField
                                      value={value}
                                      onChange={(e) => handleTextFieldChange(e, item?.id, column.accessor)}
                                      size="small"
                                      variant="outlined"
                                      inputProps={{
                                        style: { textAlign: "center" },
                                      }}
                                    />
                                  ) : (
                                    <TextField
                                      value={value}
                                      onChange={(e) => handleTextFieldChange(e, item?.id, column.accessor)}
                                      size="small"
                                      variant="outlined"
                                      inputProps={{
                                        style: { textAlign: "center" },
                                      }}
                                    />
                                  )
                                ) : (
                                  <ListItemText
                                    className="listIem-text"
                                    sx={{ textAlign: "center" }}
                                    primary={truncateText(value, 20)}
                                  />
                                )
                              ) : column.accessor ===
                                  "appraisal_details.non_billable_percentage" ||
                                column.accessor ===
                                  "appraisal_details.billable_percentage" ? (
                                selectedYear === currentFinancialYear ? (
                                  isDraft ? (
                                    <TextField
                                      value={value}
                                      disabled
                                      inputProps={{
                                        style: { textAlign: "center" },
                                      }}
                                    />
                                  ) : isEditMode ? (
                                    <TextField
                                      value={value}
                                      onChange={(e) => handleTextFieldChange(e, item?.id, column.accessor)}
                                      size="small"
                                      variant="outlined"
                                      inputProps={{
                                        style: { textAlign: "center" },
                                      }}
                                    />
                                  ) : (
                                    <TextField
                                      value={value}
                                      onChange={(e) => handleTextFieldChange(e, item?.id, column.accessor)}
                                      size="small"
                                      variant="outlined"
                                      inputProps={{
                                        style: { textAlign: "center" },
                                      }}
                                    />
                                  )
                                ) : (
                                  <ListItemText
                                    className="listIem-text"
                                    style={{
                                      textAlign: "left",
                                      margin: "40px",
                                    }}
                                    primary={truncateText(value, 20)}
                                  />
                                )
                              ) : column?.accessor === "slab_mapping.score" ? (
                                isEditMode ? (
                                  <TextField
                                    value={item.slab_mapping?.score ?? 0}
                                    onChange={(e) => handleTextFieldChange(e, item?.id, column.accessor)}
                                    size="small"
                                    sx={{ width: "80px" }}
                                    variant="outlined"
                                    inputProps={{
                                      style: { textAlign: "center" },
                                    }}
                                  />
                                ) : (
                                  <ListItemText
                                    className="listIem-text"
                                    primary={item.slab_mapping?.score ?? 0}
                                  />
                                )
                              ) : column?.accessor === "name" ? (
                                <Tooltip title={item?.name} arrow>
                                  <ListItemText
                                    className="listIem-text"
                                    primary={
                                      actions?.length !== 0 ? (
                                        <>
                                          {truncateText(item?.name, 20)}
                                          {item?.specialSubCategory && (
                                            <Typography component="span" style={{ color: 'red', fontSize: '20px' }}>
                                              {' *'}
                                            </Typography>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item?.name}
                                          {item?.specialSubCategory && (
                                            <Typography component="span" style={{ color: 'red', fontSize: '20px' }}>
                                              {' *'}
                                            </Typography>
                                          )}
                                        </>
                                      )
                                    }
                                  />
                                </Tooltip>
                              ) : column?.accessor === "category" ? (
                                <Tooltip title={item?.category} arrow>
                                  <ListItemText
                                    className="listIem-text"
                                    primary={truncateText(item?.category, 20)}
                                  />
                                </Tooltip>
                              ) : column?.accessor === "full_name" ? (
                                <Tooltip title={item?.full_name} arrow>
                                  <ListItemText
                                    className="listIem-text"
                                    primary={truncateText(item?.full_name, 20)}
                                  />
                                </Tooltip>
                              ) : column?.accessor === "department" ? (
                                <Tooltip title={item?.department} arrow>
                                  <ListItemText
                                    className="listIem-text"
                                    primary={truncateText(item?.department, 20)}
                                  />
                                </Tooltip>
                              ) : column?.accessor === "buHead" ? (
                                <Tooltip title={item?.buHead} arrow>
                                  <ListItemText
                                    className="listIem-text"
                                    primary={truncateText(item?.buHead, 20)}
                                  />
                                </Tooltip>
                              ) : column?.accessor === "title" ? (
                                <Tooltip title={item?.title} arrow>
                                  <ListItemText
                                    className="listIem-text"
                                    primary={truncateText(item?.title, 20)}
                                  />
                                </Tooltip>
                              ) : column?.accessor === "hrBPs" ? (
                                <Tooltip title={item?.hrBPs} arrow>
                                  <ListItemText
                                    className="listIem-text"
                                    primary={truncateText(item?.hrBPs, 20)}
                                  />
                                </Tooltip>
                              ) : column?.accessor === "responsibilites" ? (
                                <Tooltip
                                  title={
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item?.responsibilites,
                                      }}
                                    />
                                  }
                                  arrow
                                >
                                  <ListItemText
                                    className="listIem-text"
                                    primary={
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: String(
                                            truncateText(
                                              item?.responsibilites,
                                              20
                                            )
                                          ),
                                        }}
                                      />
                                    }
                                  />
                                </Tooltip>
                              ) : column?.accessor ===
                                "AppraisalEligibility" ? (
                                <CheckBox />
                              ) : column?.accessor ===
                                "PromotionEligibility" ? (
                                <CheckBox />
                              ) : (
                                <ListItemText
                                  className="listIem-text"
                                  primary={
                                    item?.[column?.accessor] !== undefined
                                      ? truncateText(
                                        item?.[column?.accessor],
                                        20
                                      )
                                      : ""
                                  }
                                />
                              )}
                            </ListItem>
                          </List>
                        </Box>
                      );
                    })}
                    {isStatus && hasDSGNPermission && (
                      <Box className="status-field-wrap" m={2}>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          style={{ marginLeft: "15px" }}
                        >
                            <AntSwitch
                              checked={item["status"] === 1 || item["status"] === true}
                              inputProps={{ "aria-label": "ant design" }}
                              onClick={() => {
                                if (onActionClick) {
                                  onActionClick({ label: "status" }, item);
                                }
                              }}
                            />
                        </Stack>
                      </Box>
                    )}
                    {actions &&
                      //heading !== "Users" &&
                      tableName !== "Budget Details" &&
                      tableName !== "Career-level Appraisal" &&
                      actions?.length != 0 && (
                        <Box className="actions-field-wrap">
                          {actions?.map((action, actionIndex) => {
                            const combinedAction = {
                              ...action,
                              disabled: action?.disabled && !isSubmitClicked,
                            };
                            return (
                              <span key={actionIndex}>
                                <Tooltip title={combinedAction.label} arrow>
                                  <IconButton
                                    key={index}
                                    size="large"
                                    className={
                                      combinedAction?.color
                                        ? combinedAction?.color
                                        : "primary-icon"
                                    }
                                    onClick={() => {
                                      if (onActionClick) {
                                        onActionClick(combinedAction, item);
                                      }
                                    }}
                                    disabled={combinedAction?.disabled}
                                  >
                                    {combinedAction?.icon}
                                  </IconButton>
                                </Tooltip>
                              </span>
                            );
                          })}
                        </Box>
                      )}
                  </div>
                );
              })
            )}
          </TabPanel>
        </TabContext>
      </div>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <div className="pgntn_box">
          <Typography
            className="showing_rslt"
            variant="body2"
            color="textSecondary"
          >
            Showing {visibleData && visibleData?.length} records out of{" "}
            {totalRecords} total.
          </Typography>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            color="primary"
            size="large"
            className="pagination cs_pgntn"
            siblingCount={1}
            boundaryCount={1}
            showFirstButton
            showLastButton
          />
        </div>
      </Box>
    </ListTableWrapper>
  );
}

export default ListTable;
